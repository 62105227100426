/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Container, Row } from 'reactstrap'
import { formatRoute } from 'react-router-named-routes'
import DocumentTitle from 'react-document-title'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { sprintf } from 'sprintf-js'

import { ADMINISTRATION } from 'constants/urls'
import { ACTIONS, ADMIN_LICENSE_BUYER, HISTORY, I18N, MATCH, PERSON, NETWORK_DETAILS } from 'constants/props'
import { CHANNEL_PARTNER_ACCOUNT_TYPE } from 'constants/accountTypes'
import { ACCOUNT_ADMIN } from 'roles'
import { BarError } from 'classes/errors'
import {
  INVALID_LICENSE_BUYER_ROLE,
  INVALID_CUSTOMER_PORTAL_ROLE,
  INVALID_SUBSCRIPTION_ROLE,
} from 'constants/apiErrorCodes'
import { StyledButton } from 'generics/StyledFormComponents'
import api from 'api'
import PeopleList from 'containers/PeopleList'
import BundlesCard from 'containers/BundlesCard'
import CustomizationsCard from 'containers/CustomizationsCard'
import Translation from 'generics/Translation'

import AccountDetails from './components/AccountDetails'
import ModalLicensesQuantity from './components/ModalLicensesQuantity'
import ModalSubscriptionSuccess from './components/ModalSubscriptionSuccess'
import ModalSubscriptionError from './components/ModalSubscriptionError'
import ModalToggleRenewalEmailNotifications from './components/ModalToggleRenewalEmailNotifications/ModalToggleRenewalEmailNotifications'
import styles from './PageAdministration.scss'

const SECTIONS = [
  {
    children: <PeopleList />,
    icon: 'user',
    id: 'people',
    name: 'People',
  },
  {
    children: <BundlesCard />,
    icon: 'box1',
    id: 'content-modules',
    name: 'Content Modules',
  },
  {
    children: <CustomizationsCard />,
    icon: 'plugin',
    id: 'customization-modules',
    name: 'Customization',
  },
]

const PageAdministration = ({
  accountActions,
  adminLicenseBuyer,
  canPurchaseLicenses,
  isLicenseBuyerRole,
  currentProfile,
  hasSubscription,
  hasSubscriptionNotifications,
  history,
  i18n,
  match,
  notificationActions,
  networkActionsProps,
  networkDetails,
}) => {
  const [licenseBuyerInfo, setLicenseBuyerInfo] = useState(null)
  const [isEmailNotificationsVisible, setIsEmailNotificationsVisible] = useState(false)
  const [isPurchaseLicensesModalVisible, setIsPurchaseLicensesModalVisible] = useState(false)
  const [isOnlyPayment, setIsOnlyPayment] = useState(false)
  const [isSuccessPaymentModalVisible, setIsSuccessPaymentModalVisible] = useState(false)
  const [isFailedPaymentModalVisible, setIsFailedPaymentModalVisible] = useState(false)
  const [sections, setSections] = useState([...SECTIONS])
  const [sectionId, setSectionId] = useState(null)
  const { isLicenseBuyer } = isLicenseBuyerRole || {}
  const { accountId } = match.params
  const {
    account,
    limitedMember: limitedMemberFromProfile,
    hasBoughtLicenses: hasBoughtLicensesFromProfile,
    id: profileId,
  } = currentProfile
  const { expiresAt, isPersonal: isPersonalAccount, accountType } = account
  const { limitedMember: limitedMemberFromNetworkDetails, hasBoughtLicenses: hasBoughtLicensesFromNetworkDetails } =
    networkDetails || {}
  const formattedExpirationDate = moment(new Date(expiresAt)).utc().format('L')
  const isCorporateAdminRole = !isPersonalAccount && currentProfile.hasRole(ACCOUNT_ADMIN)
  const showMySubscriptions = !isCorporateAdminRole && isLicenseBuyer
  const isChannelPartner = accountType === CHANNEL_PARTNER_ACCOUNT_TYPE
  const isChannelPartnerAdmin = isChannelPartner && isCorporateAdminRole
  const renewalBtnId = 'automatic-renewal-management'
  const isLimitedMember =
    limitedMemberFromNetworkDetails === undefined ? limitedMemberFromProfile : limitedMemberFromNetworkDetails
  const hasBought =
    limitedMemberFromNetworkDetails === undefined ? hasBoughtLicensesFromProfile : hasBoughtLicensesFromNetworkDetails

  const handleRoleError = (error, onClose) => {
    let message
    const { errorCode, message: errorMessage } = error || {}

    if (
      errorCode === INVALID_LICENSE_BUYER_ROLE ||
      errorCode === INVALID_CUSTOMER_PORTAL_ROLE ||
      errorCode === INVALID_SUBSCRIPTION_ROLE
    ) {
      message = 'invalidBuyerRole'
      setSections([...SECTIONS])
    } else message = errorMessage

    notificationActions.notifyError(new BarError({ label: message }))
    accountActions.removePermission('isLicenseBuyer')
    if (onClose) onClose()
  }

  const handleLicensesStrategiesError = (error, onClose) => {
    const { message } = error || {}
    notificationActions.notifyError(new BarError({ label: message }))
    accountActions.removePermission('isLicenseBuyer')
    if (onClose) onClose()
  }

  const handleCreateCustomerPortal = () => {
    const onSuccess = ({ session }) => window.location.replace(session.url)
    const onError = ({ error }) => handleRoleError(error)
    api.subscriptions.createCustomerPortalSession({ accountId }, onSuccess, onError)
  }

  const handleCreateSubscription = () => {
    const onSuccess = ({ session }) => window.location.replace(session.url)
    const onError = ({ error }) => handleRoleError(error)
    api.subscriptions.createSubscriptionSession({ accountId }, onSuccess, onError)
  }

  const handlePurchaseLicenses = () => setIsPurchaseLicensesModalVisible(true)

  useEffect(() => {
    const { sectionId: sectionIdParam, stripeSubscription, subscriptionStatus } = match.params
    accountActions.fetchAccount(accountId)
    setSectionId(sectionIdParam)
    if (!sections.find(({ id }) => id === sectionIdParam)) history.go(-1) // TODO: Redirect to My 5 Dynamics

    // Payment status modal
    if (subscriptionStatus === 'success') {
      if (stripeSubscription === 'stripe_payment') setIsOnlyPayment(true)
      setIsSuccessPaymentModalVisible(true)
    }

    if (subscriptionStatus === 'error') {
      if (stripeSubscription === 'stripe_payment') setIsOnlyPayment(true)
      window.location.replace(window.location.href.replace('/error', ''))
    }

    // Set license buyer information
    if (adminLicenseBuyer) setLicenseBuyerInfo({ ...adminLicenseBuyer })

    networkActionsProps.fetchNetworkDetails(accountId, profileId)
  }, [])

  useEffect(() => {
    // Payment options depending on user subscription status
    if (hasBought && isLicenseBuyer && !sections.find(({ id }) => id === renewalBtnId))
      sections.splice(1, 0, {
        action: () =>
          showMySubscriptions || isChannelPartnerAdmin || hasSubscription
            ? handleCreateCustomerPortal()
            : setIsEmailNotificationsVisible(true),
        icon: 'cash',
        id: renewalBtnId,
        name: i18n.subscriptions.renewalManagementButton,
      })
  }, [isLimitedMember, hasBought])

  const showPurchaseButton = canPurchaseLicenses || showMySubscriptions || isLicenseBuyer

  if ((!isLicenseBuyer && isLimitedMember) || (!isCorporateAdminRole && !isChannelPartner)) return null

  /**
   * @returns infoList
   */
  const getInfoList = () => {
    const { remainingLicensesCount = 0, totalPendingInvitations } = currentProfile.account

    return [
      {
        children: totalPendingInvitations,
        label: i18n.pageAdministration.pendingInvitesLabel,
      },
      {
        children: remainingLicensesCount,
        label: i18n.pageAdministration.licensesAvailableLabel,
      },
      showMySubscriptions || isLicenseBuyer
        ? {}
        : {
            children: <span className={styles['expiration-date']}>{formattedExpirationDate}</span>,
            label: i18n.pageAdministration.expirationDateLabel,
          },
      {
        children: ' ',
        // eslint-disable-next-line no-nested-ternary
        label: showPurchaseButton ? (
          <StyledButton
            className={styles.purchase}
            color="primary"
            id="link-logout"
            onClick={() => handlePurchaseLicenses()}
            title={i18n.subscriptions.buttonText}
          >
            {i18n.subscriptions.buttonText}
          </StyledButton>
        ) : licenseBuyerInfo && Object.keys(licenseBuyerInfo).length > 0 ? (
          <>
            {licenseBuyerInfo?.name && (
              <span title={`${i18n.pageAdministration.purchaseLabelNoBuyer}`}>
                {sprintf(i18n.pageAdministration.purchaseLabelNoBuyer, { licensesBuyerName: licenseBuyerInfo?.name })}
              </span>
            )}
            {licenseBuyerInfo?.name && licenseBuyerInfo?.email && (
              <a
                aria-label={`${sprintf(i18n.pageAdministration.purchaseLabelNoBuyer, {
                  licensesBuyerName: licenseBuyerInfo?.name,
                })}${licenseBuyerInfo?.email}`}
                href={`mailto:${licenseBuyerInfo?.email}`}
                rel="noopener noreferrer"
                target="_blank"
                title={`${sprintf(i18n.pageAdministration.purchaseLabelNoBuyer, {
                  licensesBuyerName: licenseBuyerInfo?.name,
                })}${licenseBuyerInfo?.email}`}
              >
                {`${licenseBuyerInfo?.email}.`}
              </a>
            )}
          </>
        ) : (
          <>
            <span title={`${i18n.pageAdministration.purchaseLabel}${i18n.pageAdministration.contactLabel}`}>
              {i18n.pageAdministration.purchaseLabel}
            </span>
            <a
              aria-label={`${i18n.pageAdministration.purchaseLabel}${i18n.pageAdministration.contactLabel}`}
              href={`mailto:${i18n.pageAdministration.contactLabel}`}
              rel="noopener noreferrer"
              target="_blank"
              title={`${i18n.pageAdministration.purchaseLabel}${i18n.pageAdministration.contactLabel}`}
            >
              {i18n.pageAdministration.contactLabel}
            </a>
          </>
        ),
      },
    ]
  }

  const handleChangeSection = newSectionId => {
    const { action, children } = sections.find(({ id: changeSectionId }) => changeSectionId === newSectionId)

    if (children) {
      setSectionId(newSectionId)
      history.push(
        formatRoute(ADMINISTRATION, {
          accountId,
          sectionId: newSectionId,
          stripeSubscription: 'stripe_subscription',
        }),
      )
      return
    }

    if (action) action()
  }

  const selectedSection = sections.find(({ id }) => id === sectionId)
  const data = getInfoList()

  const handleSubscriptionFlow = () => (!hasSubscription ? handleCreateSubscription() : handleCreateCustomerPortal())

  const handleEmailNotificationsError = (error, onClose) => {
    let message
    const { errorCode, message: errorMessage } = error || {}

    if (
      errorCode === INVALID_LICENSE_BUYER_ROLE ||
      errorCode === INVALID_CUSTOMER_PORTAL_ROLE ||
      errorCode === INVALID_SUBSCRIPTION_ROLE
    ) {
      message = 'invalidBuyerRole'
      setSections([...SECTIONS])
    } else message = errorMessage

    notificationActions.notifyError(new BarError({ label: message }))
    accountActions.removePermission('isLicenseBuyer')
    if (onClose) onClose()
  }

  return (
    <DocumentTitle
      title={`Simpli5® - ${showMySubscriptions ? i18n.menu.mySubscriptions : i18n.menu.administration} Page`}
    >
      <>
        <article className={styles.page}>
          {selectedSection && (
            <Container>
              <Row>
                <Col xs="12" md="5" lg="4">
                  <AccountDetails
                    className={styles['account-details']}
                    data={data}
                    onChangeSection={handleChangeSection}
                    onUpdateLogo={accountActions.updateLogo}
                    profile={currentProfile}
                    sectionId={sectionId}
                    sections={sections}
                    showMySubscriptions={showMySubscriptions}
                  />
                </Col>
                <Col xs="12" md="7" lg="8">
                  {selectedSection.children}
                </Col>
              </Row>
            </Container>
          )}
        </article>
        {isPurchaseLicensesModalVisible && (
          <ModalLicensesQuantity
            accountActions={accountActions}
            accountId={accountId}
            handleRoleError={handleRoleError}
            handleLicensesStrategiesError={handleLicensesStrategiesError}
            notificationActions={notificationActions}
            onClose={() => setIsPurchaseLicensesModalVisible(false)}
            showMySubscriptions={showMySubscriptions}
            isLimitedMember={isLimitedMember}
            isChannelPartnerAdmin={isChannelPartnerAdmin}
          />
        )}
        {isEmailNotificationsVisible && (
          <ModalToggleRenewalEmailNotifications
            accountActions={accountActions}
            accountId={accountId}
            handleEmailNotificationsError={handleEmailNotificationsError}
            hasSubscriptionNotifications={hasSubscriptionNotifications}
            notificationActions={notificationActions}
            onClose={() => setIsEmailNotificationsVisible(false)}
            onFinish={handleSubscriptionFlow}
            renewalDate={formattedExpirationDate}
          />
        )}
        {isSuccessPaymentModalVisible && (
          <ModalSubscriptionSuccess
            isOnlyPayment={isOnlyPayment}
            onClose={() => {
              window.location.replace(window.location.href.replace('/success', ''))
              setIsSuccessPaymentModalVisible(false)
            }}
          />
        )}
        {isFailedPaymentModalVisible && (
          <ModalSubscriptionError
            isOnlyPayment={isOnlyPayment}
            onClose={() => {
              window.location.replace(window.location.href.replace('/error', ''))
              setIsFailedPaymentModalVisible(false)
            }}
          />
        )}
      </>
    </DocumentTitle>
  )
}

PageAdministration.propTypes = {
  accountActions: ACTIONS.isRequired,
  adminLicenseBuyer: ADMIN_LICENSE_BUYER,
  canPurchaseLicenses: PropTypes.bool.isRequired,
  isLicenseBuyerRole: PropTypes.shape({}),
  currentProfile: PERSON.isRequired,
  hasSubscription: PropTypes.bool,
  hasSubscriptionNotifications: PropTypes.bool.isRequired,
  history: HISTORY.isRequired,
  i18n: I18N.isRequired,
  match: MATCH.isRequired,
  notificationActions: ACTIONS.isRequired,
  networkActionsProps: ACTIONS.isRequired,
  networkDetails: NETWORK_DETAILS.isRequired,
}

PageAdministration.defaultProps = {
  adminLicenseBuyer: {},
  isLicenseBuyerRole: {},
  hasSubscription: false,
}

export default Translation(PageAdministration, ['pageAdministration', 'subscriptions', 'menu'])
