/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { sprintf } from 'sprintf-js'
import { Tab, Tabs } from '@material-ui/core'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useRef, useState, useEffect } from 'react'

import {
  ACTIONS,
  COMPLETE_WORKSHOP,
  I18N,
  INVITATION_GROUP,
  INVITATION_GROUPS,
  INVITATION,
  LIST,
  PERSON,
  WORKSHOPS,
} from 'constants/props'
import { ARCHIVE_GROUP_SETTING } from 'constants/archiveGroup'
import { Card, Header, Separator } from 'generics/Card'
import { DECLINED, EXPIRED, PENDING_PRO_MEMBER, PENDING_LIMITED_MEMBER } from 'constants/invitationStatus'
import { DEFAULT_STATUS_FILTER } from 'constants/searchFilters'
import { LEARNING_ACCOUNT_TYPE } from 'constants/accountTypes'
import { Person } from 'classes'
import { StyledButton } from 'generics/StyledFormComponents'
import ModalPeople from 'containers/ModalPeople'
import Translation from 'generics/Translation'
import { ACCOUNT_ADMIN } from 'roles'

import Groups from '../Groups'
import Members from '../Members'
import ModalApproveRequests from '../ModalApproveRequests'
import ModalCreateWorkshop from '../ModalCreateWorkshop'
import ModalGenerateReports from '../ModalGenerateReports'
import SentInvites from '../SentInvites'
import UpgradeMembers from '../UpgradeMembers'
import Workshops from '../Workshops'
import styles from './peopleList.scss'

const PeopleList = ({
  accountActions,
  className,
  currentGroup,
  currentProfile,
  currentWorkshop,
  groups,
  groupsActions,
  i18n,
  invitesActions,
  isDepartmentEnabled,
  isPersonalAccount,
  isLicenseBuyerRole,
  notificationActions,
  memberUpgradeRequests,
  myMembers,
  pageActions,
  profileActions,
  sentInvitations,
  workshopActions,
  workshops,
}) => {
  const { isLicenseBuyer } = isLicenseBuyerRole || {}
  const [isModalPeopleOpen, setIsModalPeopleOpen] = useState(false)
  const [isModalApproveRequestsOpen, setIsModalApproveRequestsOpen] = useState(false)
  const [isModalGenerateReportsOpen, setIsModalGenerateReportsOpen] = useState(false)
  const [isModalWorkshopOpen, setIsModalWorkshopOpen] = useState(false)
  const [profiles, setProfiles] = useState([])
  const [selectedMembers, setSelectedMembers] = useState({})
  const [tabSelected, setTabSelected] = useState('myMembers')
  const isMultipleSelection = tabSelected === 'myMembers' || tabSelected === 'memberUpgradeRequest'
  const [updatedRequests, setUpdatedRequests] = useState(false)

  const pageIndex = 1
  const selectedMembersLimit = 20

  let selectedList = useRef(null)

  const { account } = currentProfile || {}
  const { accountType, allowLimitedMember } = account || {}
  const isCorporateAdminRole = !isPersonalAccount && currentProfile.hasRole(ACCOUNT_ADMIN)
  const showMySubscriptions = !isCorporateAdminRole && isLicenseBuyer

  const fetchAll = () => {
    const { isSSOAccount } = currentProfile || {}

    if (currentProfile) {
      if (!isSSOAccount) {
        if (currentProfile.can({ invite: 'index' })) invitesActions.fetchSentInvitations({ pageIndex: 1 })

        if (currentProfile.can({ group: 'index' }) && !showMySubscriptions)
          groupsActions.fetchGroups({ pageIndex: 1, searchInfo: { ...ARCHIVE_GROUP_SETTING } })

        if (currentProfile.can({ invite: 'index' }) && allowLimitedMember)
          accountActions.fetchMemberUpgradeRequests({
            pageIndex: 1,
            filters: DEFAULT_STATUS_FILTER,
          })
      } else if (currentProfile.can({ workshop: 'index' })) workshopActions.fetchWorkshops({ pageIndex: 1 })
    }
  }

  useEffect(() => {
    fetchAll()
  }, [])

  const handleOpenCloseGenerateReportsModal = () => {
    const selectedMembersList = Object.values(selectedMembers)
      .filter(value => value && value)
      .map(profile => new Person(profile))
    setProfiles(selectedMembersList)
    setIsModalGenerateReportsOpen(!isModalGenerateReportsOpen)
  }

  const handleOpenCloseApproveRequestsModal = () => {
    const selectedMembersList = Object.values(selectedMembers)
      .filter(value => value && value)
      .map(profile => new Person(profile))
    setProfiles(selectedMembersList)
    setIsModalApproveRequestsOpen(!isModalApproveRequestsOpen)
  }

  const handleOpenClosePeopleModal = () => setIsModalPeopleOpen(!isModalPeopleOpen)

  const handleOpenCloseWorkshopModal = () => setIsModalWorkshopOpen(!isModalWorkshopOpen)

  const handleChangeList = async newTabSelected => {
    if (newTabSelected === 'groups') groupsActions.clearGroup()
    else if (newTabSelected === 'workshops') workshopActions.clearWorkshop()
    setTabSelected(newTabSelected)
  }

  const handleLoadWorkshop = workshopId =>
    workshopActions.fetchWorkshopDetails(workshopId, () => setTabSelected('workshops'))

  const handleResendInvitation = (invitationId, invitationStatus, onFinish) => {
    const onSuccess = ({ error }) => {
      if (error) notificationActions.notifyError(error)
      else notificationActions.notifySuccess(i18n.pageAdministration.peopleList.invitationSuccessMessage)
      if (onFinish) onFinish()
    }

    const onError = () => onFinish && onFinish()
    groupsActions.fetchResendInvitation({ invitationId, invitationStatus }, onSuccess, onError)
  }

  const renderTabs = () => {
    const { isSSOAccount } = currentProfile || {}
    const myMembersCount = myMembers?.meta.totalObjects || 0
    const invitesCount = sentInvitations?.meta.totalObjects || 0
    const groupsCount = groups?.meta.totalObjects || 0
    const workshopsCount = workshops?.meta.totalObjects || 0
    const memberUpgradeRequestsCount = memberUpgradeRequests?.meta.totalObjects || 0

    const tabs = [
      {
        label: sprintf(i18n.pageAdministration.peopleList.tab.members, { myMembersCount }),
        value: 'myMembers',
      },
    ]

    if (currentProfile) {
      if (!isSSOAccount) {
        if (currentProfile.can({ invite: 'index' })) {
          tabs.push({
            label: sprintf(i18n.pageAdministration.peopleList.tab.sentInvites, { invitesCount }),
            value: 'sentInvitations',
          })
        }
        if (!showMySubscriptions && currentProfile.can({ group: 'index' })) {
          tabs.push({
            label: sprintf(i18n.pageAdministration.peopleList.tab.groups, { groupsCount }),
            value: 'groups',
          })
        }
        if (allowLimitedMember) {
          tabs.push({
            label: sprintf(i18n.pageAdministration.peopleList.tab.memberUpgradeRequests, {
              memberUpgradeRequestsCount,
            }),
            value: 'memberUpgradeRequest',
          })
        }
      } else if (currentProfile.can({ workshop: 'index' })) {
        tabs.push({
          label: sprintf(i18n.pageAdministration.peopleList.tab.workshops, { workshopsCount }),
          value: 'workshops',
        })
      }
    }

    return tabs.map(tab => (
      <Tab
        className={classnames(
          styles.tab,
          { [styles.tabs__expanded]: isSSOAccount },
          { [styles['tabs__not-expanded']]: !isSSOAccount },
        )}
        key={`tab-${tab.label}`}
        title={tab.label}
        {...tab}
      />
    ))
  }

  const renderLabel = status => {
    const {
      pageAdministration: { peopleList },
    } = i18n
    const { resendLabel, sendNewLabel } = peopleList

    switch (status) {
      case PENDING_PRO_MEMBER:
      case PENDING_LIMITED_MEMBER:
        return resendLabel

      case DECLINED:
      case EXPIRED:
        return sendNewLabel

      default:
        return ''
    }
  }

  const { accountId, isSSOAccount } = currentProfile || {}

  const isFetching =
    pageIndex === 1 &&
    (myMembers?.isFetching ||
      sentInvitations?.isFetching ||
      groups?.isFetching ||
      workshops?.isFetching ||
      memberUpgradeRequests?.isFetching)

  const isGenerateReportsDisabled = Object.values(selectedMembers).filter(value => value && value).length === 0
  const isApproveRequestsDisabled = Object.values(selectedMembers).filter(value => value && value).length === 0
  const isLearningAccount = accountType === LEARNING_ACCOUNT_TYPE
  const actions = []

  if (
    currentProfile?.can({ invite: 'sendInvitationViaCsv' }, { invite: 'sendInvitationManually' }) &&
    !isSSOAccount &&
    tabSelected !== 'memberUpgradeRequest'
  ) {
    actions.push(
      <StyledButton
        id="button-invite-people"
        onClick={() => handleOpenClosePeopleModal()}
        color="primary"
        style={{ display: undefined }}
        title={i18n.pageAdministration.peopleList.invitePeopleLabel}
      >
        {i18n.pageAdministration.peopleList.invitePeopleLabel}
      </StyledButton>,
    )
  } else if (currentProfile?.can({ workshop: 'create' }) && isSSOAccount) {
    actions.push(
      <StyledButton
        id="button-create-workshop"
        onClick={() => handleOpenCloseWorkshopModal()}
        color="primary"
        style={{ display: undefined }}
        title={i18n.pageAdministration.peopleList.createWorkshopLabel}
      >
        {i18n.pageAdministration.peopleList.createWorkshopLabel}
      </StyledButton>,
    )
  }

  if (tabSelected === 'myMembers' && isLearningAccount) {
    actions.push(
      <StyledButton
        aria-disabled={isGenerateReportsDisabled}
        aria-label={
          isGenerateReportsDisabled
            ? 'Generate Reports. You have to select members to be able to generate reports'
            : undefined
        }
        id="button-generate-reports"
        onClick={!isGenerateReportsDisabled ? () => handleOpenCloseGenerateReportsModal() : () => {}}
        color="primary"
        style={{ display: undefined, marginLeft: 6 }}
        title={sprintf(i18n.pageAdministration.members.generateReports.selectionLabel, {
          selectedMembers: Object.values(selectedMembers).filter(value => value && value).length,
          selectedMembersLimit,
        })}
      >
        {sprintf(i18n.pageAdministration.members.generateReports.selectionLabel, {
          selectedMembers: Object.values(selectedMembers).filter(value => value && value).length,
          selectedMembersLimit,
        })}
      </StyledButton>,
    )
  }

  if (tabSelected === 'memberUpgradeRequest') {
    actions.push(
      <StyledButton
        aria-disabled={isApproveRequestsDisabled}
        aria-label={isApproveRequestsDisabled ? 'Approve upgrade member requests to member.' : undefined}
        id="button-approve-requests"
        onClick={!isApproveRequestsDisabled ? () => handleOpenCloseApproveRequestsModal() : () => {}}
        color="primary"
        style={{ display: undefined, marginLeft: 6 }}
        title={sprintf(i18n.pageAdministration.members.generateReports.selectionLabel, {
          selectedMembers: Object.values(selectedMembers).filter(value => value && value).length,
        })}
      >
        {sprintf(i18n.pageAdministration.upgradeMemberRequests.approve.selectionLabel, {
          selectedMembers: Object.values(selectedMembers).filter(value => value && value).length,
        })}
      </StyledButton>,
    )
  }

  return (
    <Card
      barClassName={styles.bar}
      className={classnames(styles.people, className)}
      disabled={isPersonalAccount}
      isFetching={isFetching}
    >
      <Header
        ariaLabel={i18n.pageAdministration.peopleList.peopleLabel}
        actions={actions}
        className={styles.header}
        title={i18n.pageAdministration.peopleList.peopleLabel}
      />

      <Tabs
        className={styles.tabs}
        indicatorColor="primary"
        onChange={(e, value) => handleChangeList(value)}
        textColor="primary"
        value={tabSelected}
      >
        {renderTabs()}
      </Tabs>

      <Separator />
      {tabSelected === 'myMembers' && (
        <>
          <Members
            accountActions={accountActions}
            currentProfile={currentProfile}
            isDepartmentEnabled={isDepartmentEnabled}
            isMultipleSelection={isMultipleSelection && isLearningAccount}
            isMultipleSelectionDisabled={isMultipleSelection && isLearningAccount}
            isLicenseBuyer={isLicenseBuyer}
            notificationActions={notificationActions}
            myMembers={myMembers}
            onLoadWorkshop={handleLoadWorkshop}
            pageActions={pageActions}
            profileActions={profileActions}
            selectedMembers={selectedMembers}
            selectedMembersLimit={selectedMembersLimit}
            setSelectedMembers={setSelectedMembers}
            ref={nodeElement => {
              selectedList = nodeElement
            }}
          />
        </>
      )}
      {tabSelected === 'sentInvitations' && (
        <SentInvites
          accountActions={accountActions}
          accountId={accountId}
          invitesActions={invitesActions}
          allowLimitedMember={allowLimitedMember}
          onRenderLabel={renderLabel}
          onResendInvitation={handleResendInvitation}
          sentInvitations={sentInvitations}
        />
      )}
      {!showMySubscriptions && tabSelected === 'groups' && (
        <Groups
          accountActions={accountActions}
          accountId={accountId}
          currentGroup={currentGroup}
          currentProfile={currentProfile}
          groups={groups}
          groupsActions={groupsActions}
          onRenderLabel={renderLabel}
          onResendInvitation={handleResendInvitation}
          ref={nodeElement => {
            selectedList = nodeElement
          }}
        />
      )}
      {tabSelected === 'workshops' && (
        <Workshops
          currentProfile={currentProfile}
          hasMoreMembers={currentWorkshop.hasMoreMembers}
          members={currentWorkshop.members}
          pageActions={pageActions}
          workshop={currentWorkshop.workshop}
          workshopActions={workshopActions}
          workshops={workshops}
          ref={nodeElement => {
            selectedList = nodeElement
          }}
        />
      )}
      {tabSelected === 'memberUpgradeRequest' && (
        <>
          <UpgradeMembers
            accountActions={accountActions}
            currentProfile={currentProfile}
            isDepartmentEnabled={isDepartmentEnabled}
            isMultipleSelection={isMultipleSelection}
            isMultipleSelectionDisabled={isMultipleSelection}
            notificationActions={notificationActions}
            memberUpgradeRequests={memberUpgradeRequests}
            myMembers={myMembers}
            onLoadWorkshop={handleLoadWorkshop}
            onRenderLabel={renderLabel}
            pageActions={pageActions}
            profileActions={profileActions}
            selectedMembers={selectedMembers}
            selectedMembersLimit={selectedMembersLimit}
            setSelectedMembers={setSelectedMembers}
            setUpdatedRequests={setUpdatedRequests}
            updatedRequests={updatedRequests}
            allowLimitedMember={allowLimitedMember}
            ref={nodeElement => {
              selectedList = nodeElement
            }}
          />
        </>
      )}
      {isModalGenerateReportsOpen && (
        <ModalGenerateReports
          accountId={accountId}
          notificationActions={notificationActions}
          onClose={() => setIsModalGenerateReportsOpen(false)}
          onFinish={() => setSelectedMembers({})}
          pageActions={pageActions}
          profiles={profiles}
        />
      )}
      {isModalApproveRequestsOpen && (
        <ModalApproveRequests
          accountId={accountId}
          notificationActions={notificationActions}
          onClose={() => setIsModalApproveRequestsOpen(false)}
          onFinish={() => {
            accountActions.fetchAccount(accountId)
            setSelectedMembers({})
            setUpdatedRequests(true)
          }}
          pageActions={pageActions}
          profiles={profiles}
        />
      )}
      {isModalPeopleOpen && (
        <ModalPeople
          onClose={() => handleOpenClosePeopleModal()}
          onComplete={fetchAll}
          showMySubscriptions={showMySubscriptions}
        />
      )}
      {isModalWorkshopOpen && (
        <ModalCreateWorkshop
          accountId={accountId}
          onClose={() => handleOpenCloseWorkshopModal('ModalWorkshop')}
          onComplete={fetchAll}
          pageActions={pageActions}
        />
      )}
    </Card>
  )
}

PeopleList.propTypes = {
  accountActions: ACTIONS.isRequired,
  className: PropTypes.string,
  currentGroup: INVITATION_GROUP,
  currentProfile: PERSON.isRequired,
  currentWorkshop: COMPLETE_WORKSHOP.isRequired,
  groups: INVITATION_GROUPS.isRequired,
  groupsActions: ACTIONS.isRequired,
  i18n: I18N.isRequired,
  invitesActions: ACTIONS.isRequired,
  isDepartmentEnabled: PropTypes.bool.isRequired,
  isPersonalAccount: PropTypes.bool,
  isLicenseBuyerRole: PropTypes.shape({}).isRequired,
  memberUpgradeRequests: LIST(PERSON).isRequired,
  myMembers: LIST(PERSON).isRequired,
  notificationActions: ACTIONS.isRequired,
  pageActions: ACTIONS.isRequired,
  profileActions: ACTIONS.isRequired,
  sentInvitations: LIST(INVITATION).isRequired,
  workshops: WORKSHOPS.isRequired,
  workshopActions: ACTIONS.isRequired,
}

PeopleList.defaultProps = {
  className: null,
  currentGroup: null,
  isPersonalAccount: false,
}

export default Translation(PeopleList, ['pageAdministration'])
