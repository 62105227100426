import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import accounts from 'app_modules/accounts/reducers'
import assessment from 'app_modules/assessment/reducers'
import bundles from 'app_modules/bundles/reducers'
import groups from 'app_modules/groups/reducers'
import intlReducer from 'app_modules/intl/reducers'
import invites from 'app_modules/invites/reducers'
import network from 'app_modules/network/reducers'
import notifications from 'app_modules/notifications/reducers'
import page from 'app_modules/page/reducers'
import proMember from 'app_modules/proMember/reducers'
import session from 'app_modules/session/reducers'
import teams from 'app_modules/teams/reducers'
import training from 'app_modules/training/reducers'
import user from 'app_modules/user/reducers'
import workshops from 'app_modules/workshops/reducers'

export default history =>
  combineReducers({
    accounts,
    assessment,
    bundles,
    groups,
    intl: intlReducer,
    invites,
    network,
    notifications,
    page,
    proMember,
    router: connectRouter(history),
    session,
    teams,
    training,
    user,
    workshops,
  })
