/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import api from 'api'
import Modal from 'generics/Modal'
import Translation from 'generics/Translation'
import classNames from 'classnames'

import FormLicensesQuantity from '../FormLicensesQuantity'
import styles from './ModalLicensesQuantity.scss'

const ModalLicensesQuantity = ({
  accountId,
  handleRoleError,
  handleLicensesStrategiesError,
  isFetching,
  onClose,
  title,
  showMySubscriptions,
  isLimitedMember,
  isChannelPartnerAdmin,
}) => {
  const [licensesStrategies, setLicensesStrategies] = useState([])
  const isForStrategies = showMySubscriptions || isChannelPartnerAdmin

  const handleSubmit = values => {
    const { quantity, licenseStrategyId, depthOfHierarchy, autoSent } = values
    const onSuccess = ({ session }) => window.location.replace(session.url)
    const onError = ({ error }) => handleRoleError(error, onClose)
    api.subscriptions.createPaymentSession(
      { accountId, quantity, licenseStrategyId, depthOfHierarchy, autoSent },
      onSuccess,
      onError,
    )
  }

  const getLicensesStrategies = () => {
    const handleResponse = ({ licensesStrategies: strategies }) => setLicensesStrategies(strategies)
    const handleError = ({ error }) => handleLicensesStrategiesError(error, onClose)
    api.licenses.getLicensesStrategies(accountId, handleResponse, handleError)
  }

  useEffect(() => {
    if (isForStrategies) getLicensesStrategies()
  }, [])

  return (
    <Modal
      card={{
        barClassName: styles.bar,
        isFetching,
      }}
      modalCardClassName={classNames(styles.modal, isForStrategies ? styles.extended : null)}
      onClose={onClose}
      switchModalView={false}
      title={title}
    >
      <FormLicensesQuantity
        onClose={onClose}
        onSubmit={handleSubmit}
        licensesStrategies={licensesStrategies}
        isForStrategies={isForStrategies}
        isLimitedMember={isLimitedMember}
      />
    </Modal>
  )
}

ModalLicensesQuantity.propTypes = {
  accountId: PropTypes.number.isRequired,
  handleRoleError: PropTypes.func.isRequired,
  handleLicensesStrategiesError: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  showMySubscriptions: PropTypes.bool,
  isLimitedMember: PropTypes.bool,
  isChannelPartnerAdmin: PropTypes.bool,
}

ModalLicensesQuantity.defaultProps = {
  showMySubscriptions: false,
  isLimitedMember: true,
  isChannelPartnerAdmin: false,
}

export default Translation(ModalLicensesQuantity, ['subscriptions'])
