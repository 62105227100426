export const en = {
  hiddenResults:
    'At the request of your course facilitator, your assessment results are on hold for now. They will be available to you on %s so please be sure to log back in at that time.',
  welcomeBack: {
    editProfile: 'Edit Profile',
    energyMap: 'Energy Map Graphic',
    learningReport: 'Learning Report PDF',
    learnMore: 'Learn More',
    overview: 'Overview ',
    overviewReport: 'Overview Report PDF',
    readMore: 'Read More',
    title: 'Welcome Back, %s',
    upgrade: 'Upgrade to learn more',
  },
  videos: [
    {
      category: 'About 5 Dynamics®',
      description:
        'Simpli5®, powered by 5 Dynamics®, gives you the power to understand YOUR natural tendencies as well as the natural tendencies of your co-workers. This awareness will help you gain a new perspective of yourself & your teammates. Watch this brief video to learn more.',
      title: 'Welcome to Simpli5®',
    },
    {
      category: 'About 5 Dynamics®',
      description:
        'Now that you have a basic understanding of your 5 Dynamics® Energetic preferences, learn how to apply this knowledge to accomplish more. In this brief video, we demystify team dynamics and make your "people problems" disappear.',
      title: 'Teaming for Optimal Results',
    },
    {
      category: 'About 5 Dynamics®',
      description:
        'Simpli5®, powered by 5 Dynamics®, is a quick 3-minute energetic evaluation that helps you unlock your full potential. Click here to watch a short video detailing more about our brain-based methodology.',
      title: 'Welcome to Simpli5®',
    },
    {
      category: 'About 5 Dynamics®',
      description:
        'Simpli5® gives you the power to understand HOW you learn so you can set yourself up for greater success throughout your college career. Watch this brief video to learn more about how we can help you optimize your learning experience and guide you throughout all your subsequent endeavors.',
      title: 'Succeeding with Simpli5®',
    },
  ],
}

export const es = en
