/**
 * @description API controllers
 * @module API controllers
 */

import accounts from 'api/controllers/accounts.controller'
import bundles from 'api/controllers/bundles.controller'
import charges from 'api/controllers/charges.controller'
import connectionRequests from 'api/controllers/connectionRequests.controller'
import contentManager from 'api/controllers/contentManager.controller'
import countries from 'api/controllers/countries.controller'
import files from 'api/controllers/files.controller'
import groups from 'api/controllers/groups.controller'
import invites from 'api/controllers/invites.controller'
import licenses from 'api/controllers/licenses.controller'
import news from 'api/controllers/news.controller'
import profiles from 'api/controllers/profiles.controller'
import proMember from 'api/controllers/proMember.controller'
import roles from 'api/controllers/roles.controller'
import session from 'api/controllers/session.controller'
import subscriptions from 'api/controllers/subscriptions.controller'
import teams from 'api/controllers/teams.controller'
import tokens from 'api/controllers/tokens.controller'
import tracking from 'api/controllers/tracking.controller'
import training from 'api/controllers/training.controller'
import users from 'api/controllers/users.controller'
import videos from 'api/controllers/videos.controller'
import workshops from 'api/controllers/workshops.controller'

export default {
  accounts,
  bundles,
  charges,
  connectionRequests,
  contentManager,
  countries,
  files,
  groups,
  invites,
  licenses,
  news,
  profiles,
  proMember,
  roles,
  session,
  subscriptions,
  teams,
  tokens,
  tracking,
  training,
  users,
  videos,
  workshops,
}
