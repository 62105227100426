/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { MdCheckCircle } from 'react-icons/md'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { formatRoute } from 'react-router-named-routes'
import { ADMINISTRATION } from 'urls'
import * as networkActions from 'app_modules/network/actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { ACTIONS, I18N } from 'constants/props'
import { BarError } from 'classes/errors'
import { StyledButton } from 'generics/StyledFormComponents'
import api from 'api'
import Modal from 'generics/Modal'
import Translation from 'generics/Translation'
import { NavLink as Link } from 'react-router-dom'
import { CHANNEL_PARTNER_ACCOUNT_TYPE } from 'constants/accountTypes'

import styles from './ModalUpgradePro.scss'

const { PUBLIC_PATH } = process.env
const TRAINING_CENTER_IMG = `${window.location.origin}${PUBLIC_PATH}training/training-center.png`

const ModalUpgradePro = ({
  accountId,
  profileId,
  i18n,
  isFetching,
  notificationActionsProps,
  networkActionsProps,
  onClose,
  title,
  accountType,
}) => {
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const AdministrationRoute = formatRoute(ADMINISTRATION, { accountId, sectionId: 'people' })
  const isChannelPartner = accountType === CHANNEL_PARTNER_ACCOUNT_TYPE

  const handleSubmit = () => {
    setButtonsDisabled(true)
    const onSuccess = ({ message }) => {
      onClose()
      networkActionsProps.fetchNetworkDetails(accountId, profileId)
      notificationActionsProps.notifySuccess(message || i18n.proMember.modal.successMessage)
    }

    const onError = ({ error }) => {
      onClose()
      notificationActionsProps.notifyError(
        new BarError({
          ...error,
          message: error?.message,
        }),
      )
    }

    api.proMember.upgradeMemberRequest({ accountId }, onSuccess, onError)
  }

  return (
    <Modal
      card={{
        barClassName: styles.bar,
        isFetching,
      }}
      modalCardClassName={styles.modal}
      onClose={onClose}
      switchModalView={false}
      title={title}
    >
      <h1 className={styles.title}>{i18n.proMember.modal.title}</h1>
      <p className={styles.description}>{i18n.proMember.modal.description}</p>
      <div className={styles['banner-image']}>
        <figure>
          <img alt={i18n.proMember.modal.title} className={styles.image} src={TRAINING_CENTER_IMG} />
        </figure>
        <div>
          <ul>
            {i18n.proMember.modal.list.map(({ title: itemTitle, description }) => (
              <li key={itemTitle + description}>
                <MdCheckCircle />
                <span>
                  <b>{itemTitle}</b>
                  {description}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles['submit-banner']}>
        <p>{isChannelPartner ? i18n.proMember.modal.purchaseDescription : i18n.proMember.modal.submitDescription}</p>
        <div className={styles.actions}>
          {isChannelPartner && (
            <Link
              className={styles.link}
              href={AdministrationRoute}
              title={i18n.proMember.modal.purchaseButton}
              to={AdministrationRoute}
              onClick={onClose}
            >
              {i18n.proMember.modal.purchaseButton}
            </Link>
          )}
          <StyledButton
            color="primary"
            variant={isChannelPartner ? 'outlined' : 'contained'}
            id="upgrade-pro-button"
            onClick={handleSubmit}
            title={i18n.proMember.modal.submitButton}
            disabled={buttonsDisabled}
          >
            {i18n.proMember.modal.submitButton}
          </StyledButton>
        </div>
      </div>
    </Modal>
  )
}

ModalUpgradePro.propTypes = {
  accountId: PropTypes.number.isRequired,
  profileId: PropTypes.number.isRequired,
  i18n: I18N.isRequired,
  isFetching: PropTypes.bool,
  notificationActionsProps: ACTIONS.isRequired,
  networkActionsProps: ACTIONS.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  accountType: PropTypes.string.isRequired,
}

ModalUpgradePro.defaultProps = {
  isFetching: false,
  title: '',
}

const mapDispatchToProps = dispatch => ({
  networkActionsProps: bindActionCreators(networkActions, dispatch),
})

export default connect(null, mapDispatchToProps)(Translation(ModalUpgradePro, ['proMember']))
