import { flatten } from 'flat'

import { en as accountProfile } from '../components/AccountProfile'
import { en as applicationFooter } from '../components/ApplicationFooter'
import { en as applicationHeader } from '../components/ApplicationHeader'
import { en as assessment } from '../components/Assessment'
import { en as contentModules } from '../components/ContentModules'
import { en as customizations } from '../components/Customizations'
import { en as errorPages } from '../components/ErrorPages'
import { en as fileReader } from '../components/FileReader'
import { en as invitationDialog } from '../components/InvitationDialog'
import { en as generics } from '../components/Generics'
import { en as linkRelationshipsProfile } from '../components/PageLinkRelationshipsProfile'
import { en as login } from '../components/PageLogin'
import { en as loginSSOProviders } from '../components/PageSSOLoginProviders'
import { en as menu } from '../components/Menu'
import { en as modulesListItem } from '../components/ModulesListItem'
import { en as myProfile } from '../components/MyProfile'
import { en as network } from '../components/PageNetwork'
import { en as news } from '../components/News'
import { en as networkProfile } from '../components/NetworkProfile'
import { en as notes } from '../components/Notes'
import { en as notifications } from '../components/Notifications'
import { en as pageAdministration } from '../components/PageAdministration'
import { en as pageGeneralAccountInformation } from '../components/PageGeneralAccountInformation'
import { en as pagePersonalDashboard } from '../components/PagePersonalDashboard'
import { en as pageSpiderGraph } from '../components/PageSpiderGraph'
import { en as pageTeamDashboard } from '../components/PageTeamDashboard'
import { en as pageTeams } from '../components/PageTeams'
import { en as pageTermsOfUse } from '../components/PageTermsOfUse'
import { en as pageTokenAdministration } from '../components/PageTokenAdministration'
import { en as pictureSelectorModal } from '../components/PictureSelectorModal'
import { en as profileDetail } from '../components/ProfileDetail'
import { en as profileSelector } from '../components/ProfileSelector'
import { en as signUp } from '../components/PageSignUp'
import { en as signUpSSO } from '../components/PageSignUpSSO'
import { en as subscriptions } from '../components/Subscriptions'
import { en as teamDescription } from '../components/TeamDescription'
import { en as thirdPersonProfile } from '../components/ThirdPersonProfile'
import { en as training } from '../components/Training'
import { en as proMember } from '../components/UpgradePro'
import { en as welcomeCardSlider } from '../components/WelcomeCardSlider'
import { en as workshops } from '../sections/Workshops'

export const messages = {
  accountProfile,
  applicationFooter,
  applicationHeader,
  assessment,
  contentModules,
  customizations,
  errorPages,
  fileReader,
  generics,
  invitationDialog,
  linkRelationshipsProfile,
  login,
  loginSSOProviders,
  menu,
  modulesListItem,
  myProfile,
  network,
  news,
  networkProfile,
  notes,
  notifications,
  pageAdministration,
  pageGeneralAccountInformation,
  pagePersonalDashboard,
  pageSpiderGraph,
  pageTeamDashboard,
  pageTeams,
  pageTermsOfUse,
  pageTokenAdministration,
  pictureSelectorModal,
  profileDetail,
  profileSelector,
  proMember,
  signUp,
  signUpSSO,
  subscriptions,
  teamDescription,
  thirdPersonProfile,
  training,
  welcomeCardSlider,
  workshops,
}

const enTranslations = {
  locale: 'en',
  messages: flatten(messages),
}

export default enTranslations
