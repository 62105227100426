import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'

import { ACTIONS, CHILDREN, PERSON } from 'constants/props'
import * as accountSelectors from 'app_modules/accounts/selectors'
import * as notificationActions from 'app_modules/notifications/actions'
import * as pageSelectors from 'app_modules/page/selectors'
import * as sessionSelectors from 'app_modules/session/selectors'
import * as proMemberActions from 'app_modules/proMember/actions'
import * as proMemberSelectors from 'app_modules/proMember/selectors'
import ApplicationFooter from 'components/ApplicationFooter'
import ApplicationHeader from 'containers/ApplicationHeader'
import ErrorBoundary from 'generics/ErrorBoundary'
import ModalNotification from 'components/ModalNotification'
import ModalUpgradePro from 'components/generics/ModalUpgradePro'

import styles from './DefaultLayout.scss'

const DefaultLayout = ({
  accountId,
  children,
  isImpersonating = false,
  isModalView = false,
  isStudentWaitingResults,
  isProMemberModalVisible,
  notificationActionsProps,
  proMemberActionsProps,
  profile,
}) => {
  const [isStickyHeader, setIsStickyHeader] = useState(0)
  const mainFocusRef = useRef(null)
  const {
    account: { accountType },
    id: profileId,
  } = profile

  return (
    <div className={styles.layout}>
      <ApplicationHeader
        className={classnames(styles.header, { [styles['header--hidden']]: isModalView })}
        focusRef={mainFocusRef}
        onChange={isSticky => {
          setIsStickyHeader(isSticky)
        }}
      />
      <main
        className={classnames(
          styles.main,
          { [styles['main--has-sticky-header']]: isStickyHeader },
          { [styles['main--has-impersonate-label']]: isImpersonating },
          { [styles['main--has-hidden-results-label']]: isStudentWaitingResults },
        )}
        ref={mainFocusRef}
      >
        <ErrorBoundary className={styles['error-boundary']}>{children}</ErrorBoundary>
      </main>
      <ApplicationFooter className={styles.footer} />
      <ModalNotification />
      {isProMemberModalVisible && (
        <ModalUpgradePro
          accountId={accountId}
          profileId={profileId}
          notificationActionsProps={notificationActionsProps}
          onClose={() => proMemberActionsProps?.toggleProMemberModal(false)}
          accountType={accountType}
        />
      )}
    </div>
  )
}

DefaultLayout.propTypes = {
  accountId: PropTypes.number,
  children: CHILDREN,
  isImpersonating: PropTypes.bool,
  isModalView: PropTypes.bool,
  isStudentWaitingResults: PropTypes.bool.isRequired,
  isProMemberModalVisible: PropTypes.bool.isRequired,
  notificationActionsProps: ACTIONS.isRequired,
  proMemberActionsProps: ACTIONS.isRequired,
  profile: PERSON.isRequired,
}

DefaultLayout.defaultProps = {
  accountId: null,
  children: null,
  isImpersonating: false,
  isModalView: false,
}

const mapStateToProps = state => ({
  accountId: sessionSelectors.accountId(state),
  isImpersonating: sessionSelectors.isImpersonating(state),
  isModalView: pageSelectors.isModalView(state),
  isStudentWaitingResults: accountSelectors.isStudentWaitingResults(state),
  isProMemberModalVisible: proMemberSelectors.isProMemberModalVisible(state),
  profile: sessionSelectors.currentProfile(state),
})

const mapDispatchToProps = dispatch => ({
  notificationActionsProps: bindActionCreators(notificationActions, dispatch),
  proMemberActionsProps: bindActionCreators(proMemberActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout)
