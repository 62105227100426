import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as accountActions from 'app_modules/accounts/actions'
import * as accountsSelectors from 'app_modules/accounts/selectors'
import * as groupsActions from 'app_modules/groups/actions'
import * as groupsSelectors from 'app_modules/groups/selectors'
import * as invitesActions from 'app_modules/invites/actions'
import * as invitesSelectors from 'app_modules/invites/selectors'
import * as notificationActions from 'app_modules/notifications/actions'
import * as profileActions from 'app_modules/profile/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import * as workshopActions from 'app_modules/workshops/actions'
import * as workshopSelectors from 'app_modules/workshops/selectors'
import * as pageActions from 'app_modules/page/actions'
import PeopleList from 'components/PageAdministration/components/PeopleList'

const mapStateToProps = state => ({
  currentGroup: groupsSelectors.currentGroup(state).group,
  currentProfile: sessionSelectors.currentProfile(state),
  currentWorkshop: workshopSelectors.currentWorkshop(state),
  groups: groupsSelectors.groups(state),
  isDepartmentEnabled: accountsSelectors.isDepartmentEnabled(state),
  isLicenseBuyerRole: accountsSelectors.isLicenseBuyerRole(state),
  isPersonalAccount: sessionSelectors.isPersonal(state),
  memberUpgradeRequests: accountsSelectors.memberUpgradeRequests(state),
  myMembers: accountsSelectors.myMembers(state),
  sentInvitations: invitesSelectors.sent(state),
  user: sessionSelectors.user(state),
  workshops: workshopSelectors.workshops(state),
})

const mapDispatchToProps = dispatch => ({
  accountActions: bindActionCreators(accountActions, dispatch),
  groupsActions: bindActionCreators(groupsActions, dispatch),
  invitesActions: bindActionCreators(invitesActions, dispatch),
  notificationActions: bindActionCreators(notificationActions, dispatch),
  pageActions: bindActionCreators(pageActions, dispatch),
  profileActions: bindActionCreators(profileActions, dispatch),
  workshopActions: bindActionCreators(workshopActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PeopleList)
